<template>
  <k-form-design
    ref="edit_f"
    @save="handleSave"
    toolbarsTop
    :toolbars="['save', 'preview', 'reset']"
    showToolbarsText
    :showHead="false"
    ifHeader
  />
</template>
<script>
const defaultData = {
  list: [],
  config: {
    layout: 'horizontal',
    labelCol: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
    labelWidth: 100,
    labelLayout: 'flex',
    wrapperCol: { xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 },
    hideRequiredMark: false,
    customStyle: ''
  }
};
import * as Request from '@/api/starter';
import store from 'storejs';
export default {
  data() {
    return {
      jsonData: {}
    };
  },
  created() {
    const formId = this.$route.params.formResourceId;
    const token = this.$route.params.token;
    store.set('FormVueToken', token);
    Request.formResourceDetail({ formResourceId: formId }).then(res => {
      this.$refs.edit_f.handleSetData(res.data.formJson ? JSON.parse(res.data.formJson) : defaultData);
    });
  },
  methods: {
    handleSave(values) {
      console.log(values);
      const formId = this.$route.params.formResourceId;
      const formName = this.$route.params.formResourceName;
      const category = this.$route.params.category;
      const formResourceCode = this.$route.params.formResourceCode;
      Request.publishDesignForm({
        formResourceCode: formResourceCode,
        formResourceId: formId,
        formResourceName: formName,
        category: category,
        formJson: values,
        status: 1
      }).then(res => {
        if (res.success) {
          this.$message.success('保存成功');
        }
      });
    }
  }
};
</script>